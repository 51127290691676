.tool-tip {
  border-radius: 10px !important;
}

/* .swal-container {
  border-radius: 30px !important;
}

.swal-title {
  font-size: 20px !important;
} */
