/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(218, 205, 182, 0.6);
  border-radius: 5px;
}

.tool-tip-search {
  opacity: 0.9 !important;
  border-radius: 10px !important;
}

/* ::-webkit-scrollbar {
  display: none;
} */
