.pac-container {
  z-index: 10000 !important;
  width: auto !important;
  position: initial !important;
  left: 0 !important;
  right: 0 !important;
  display: block !important;
}
.pac-container:empty {
  display: none !important;
}

.pac-item:hover {
  background-color: red;
}
